.notified-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 3rem 0;
  background-color: rgb(25, 25, 25);
}

.notified-body {
  margin-top: 8rem;
  width: 40rem;
  margin-bottom: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.notified-body h1 {
  font-size: 3rem;
}

.notified-body p {
  font-size: 1.4rem;
}
.get-started {
  margin-top: 5rem;
}
.get-started input {
  width: 25rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: none;
  outline: none;
  border-right: 2px solid black;
  border: 4px solid #e7e7e7;
  transition: all 0.2s;
}

/* rgb(131, 237, 131) */

.get-started input:focus {
  border: 4px solid rgb(217, 217, 217);
}

.get-started button {
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  padding: 0.5rem 2rem;
  border: 4px solid #e7e7e7;
  outline: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  background-color: #e7e7e7;
  transition: all 0.4s;
}

.get-started button:hover {
  background-color: rgb(131, 237, 131);
  border: 4px solid rgb(131, 237, 131);
}

.conn-email {
  margin-top: 3rem;
}

.conn-email a {
  color: orange;
  font-size: 1.5rem;
  text-decoration: none;
}

.response {
  display: none;
}

.asterisk {
  color: red;
}

.indicates-required {
  font-size: 0.8rem;
  display: flex;
  flex-direction: row;
}

.subscribe-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 0rem;
  margin-top: 1.5rem;
}

#mce-EMAIL {
  border: none;
  color: black;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  text-align: left;
  font-size: 1rem;
  cursor: pointer;
}

.subscribe-button {
  background-color: #ff6500;
  border: none;
  color: black;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
}

.subscribe-tagline {
  font-size: 0.7rem;
  color: white;
  margin-top: 0.5rem;
}

@media (max-width: 720px) {
  .notified-body h1 {
    font-size: 2rem;
  }

  .notified-body p {
    font-size: 1.2rem;
    padding: 0 2rem;
  }
  .get-started {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .get-started input {
    width: 20rem;
    border-radius: 10px;
  }
  .get-started button {
    margin-top: 1rem;
    border-radius: 10px;
  }
  .subscribe-form {
    flex-direction: column;
    align-items: center;
  }
}

.shimmer {
  color: white;
  display: inline-block;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
    100%;
  background-repeat: no-repeat;
  background-clip: text;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}
