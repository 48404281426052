header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 2rem 5rem;
    align-items: center;
}


.left-header {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.left-header img {
    height: 2.5rem;
}

.left-header h1 {
    /* font-size: rem; */
    font-family: 'Poppins', sans-serif;
    /* font-weight: 400; */
    margin-left: 1rem;
}

.right-header {
    display: flex;
    align-items: center;
    
}

.right-header a {
    text-decoration: none;
    color: white;
    font-size: 1.4rem;
}

.show-products {
    appearance: none;
    background-color: transparent;
    border: 1.5px solid #90909042;
    /* border: none; */
    border-radius: 15px;
    box-sizing: border-box;
    color: #909090;
    cursor: pointer;
    display: inline-block;
    line-height: normal;
    margin: 0;
    /* min-height: 60px; */
    min-width: 0;
    outline: none;
    padding: 1rem 3rem;
    font-size: 1.1rem;
    text-align: center;
    text-decoration: none;
    transition: all 400ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    /* width: 4rem; */
    will-change: transform;
    /* margin-left: 1rem; */
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}

.show-products:disabled {
    pointer-events: none;
}

.show-products:hover {
    color: #000000;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    font-weight: 500;

}

.show-products:active {
    box-shadow: none;
    transform: translateY(0);
}

.nav-option {
    display: flex;
    flex-wrap: wrap;
    list-style: none;

}

.nav-option li {
    margin: 1rem 0;
    margin-right: 3rem;
    color:  rgb(151, 151, 151);
    font-weight: 600;

}

.nav-option li:hover {
    color: white;
}

.nav-option button {
    text-decoration: none;
    color:  rgb(151, 151, 151);
    font-weight: 600;
    transition: all .4s;
    background-color: transparent;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    border: none;
    outline: none;
    cursor: pointer;
}
.nav-option button:hover{
    color: white;
}

.burger {
    display: none;
}

.dropdown-btn {
    border: 1px solid rgb(121, 121, 121);
    width: fit-content;
    padding: 0 .5rem;
    padding-top: .5rem;
    border-radius: 5px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #4c4c4c7a;
    border-radius: 10px;
    /* min-width: 10rem; */
    z-index: 1;
    margin-left: -5rem;
}

.dropdown-content button {
    color: white;
    padding: 1rem 2rem;
    text-decoration: none;
    display: block;
    background-color: transparent;
    border: none;
    outline: none;
    font-family: 'Poppins', sans-serif;
    border-radius: 10px;
    width: 100%;

}

.dropdown-content button:hover {
    background-color: #afafaf4a;
}

.dropdown-nav:hover .dropdown-content {
    display: block;
}



@media (max-width: 768px) {
    .center-header, .right-header {
      display: none; /* Hide full navigation in mobile view */
    }
  
    .burger {
      display: block; /* Show burger icon in mobile view */
    }
    header {
        padding: 2rem;
    }
  }
