.product-container {
    background-color: #191919;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 0;
}

.product-sneak-app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.product-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
}

.product-header img {
    height: 5rem;
    /* padding: 0 2rem; */
    transition: all 1s;
    cursor: pointer;

}

.product-header img:hover {
    transform: rotate(180deg);
}

.product-header h1 {
    font-size: 6rem;
    font-weight: 400;
}

.product-description {
    width: 30rem;
    margin-top: 5rem;
}

.product-description p {
    font-size: 2rem;
    text-align: center;
}


/* CSS */
.try-sneak-btn {
    margin-top: 5rem;
    align-items: center;
    background-color: #e7e7e7;
    border: 2px solid #111;
    border-radius: 30px;
    box-sizing: border-box;
    color: #111;
    cursor: pointer;
    display: flex;
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    height: 4rem;
    /* width: ; */
    justify-content: center;
    line-height: 24px;
    max-width: 100%;
    padding: 0 2rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.try-sneak-btn:after {
    background-color: #111;
    border-radius: 8px;
    content: "";
    display: block;
    height: 48px;
    left: 0;
    width: 100%;
    position: absolute;
    top: -2px;
    transform: translate(8px, 8px);
    transition: transform .2s ease-out;
    z-index: -1;
}

.try-sneak-btn:hover:after {
    transform: translate(0, 0);
}

.try-sneak-btn:active {
    background-color: #ffdeda;
    outline: 0;
}

.try-sneak-btn:hover {
    outline: 0;
}

@media (min-width: 768px) {
    .try-sneak-btn {
        padding: 0 40px;
    }
}

/* CSS */
.try-sneak-btn-2 {
    margin-top: 5rem;
    background-color: #e8e8e8b8;
    border: 0 solid #E5E7EB;
    box-sizing: border-box;
    color: #000000;
    display: flex;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    justify-content: center;
    line-height: 1.75rem;
    padding: .75rem 1.65rem;
    position: relative;
    text-align: center;
    text-decoration: none #000000 solid;
    text-decoration-thickness: auto;
    width: 100%;
    max-width: 460px;
    position: relative;
    cursor: pointer;
    transform: rotate(-2deg);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    transition: all .2s;

}

.try-sneak-btn-2:focus {
    outline: 0;
}

.try-sneak-btn-2:after {
    content: '';
    position: absolute;
    border: 2px solid #fc8d3e;
    bottom: 10px;
    left: 8px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    transition: all .2s;

}

.try-sneak-btn-2:hover:after {
    bottom: 2px;
    left: 2px;
}

.try-sneak-btn-2:hover {
    background-color: #f2f2f2cf;

}

.mockups {
    width: 20%;
    border: 10px solid black;
    border-radius: 20px;
    cursor: pointer;
}

.product-mockups {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
}

.mockups-1 {
    position: relative;
    margin-right: -5rem;
    transition: all .5s;
}

.mockups-1:hover {
    transform: translateX(-5rem);
}

.mockups-2 {
    position: relative;
    margin-top: -5rem;
    z-index: 2;
    transition: all .5s;
}

.mockups-2:hover {
    transform: translateY(-2rem);
}




.mockups-3 {
    position: relative;
    /* z-index: 1; */
    margin-left: -5rem;
    transition: all .5s;
}

.mockups-3:hover {
    transform: translateX(5rem);
}

@media (max-width: 768px) {
    .try-sneak-btn-2 {
        padding: .75rem 3rem;
        font-size: 1.25rem;
        width: 15rem;
    }
    .product-header {
        display: flex;
        flex-direction: column;
    }

    .product-header h1 {
        font-size: 4rem;
        margin-top: 1rem;
    }

    .mockups {
        width: 10rem;
        border-radius: 20px;
    }

    .product-description {
        padding: 0 2rem;
    }

    .product-description p{
        font-size: 1.4rem;
        padding: 0 2rem;
    }
}