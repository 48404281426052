@keyframes TransitionFromTop {
    from {
        opacity: 0;
        transform: translateY(-100px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
  }
  
  @keyframes TransitionRight {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
  }
  
  @keyframes TransitionLeft {
    from {
        opacity: 0;
        transform: translateX(100px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
  }
  
  @keyframes TransitionFromButtom {
    from {
        opacity: 0;
        transform: translateY(100px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
  }

  @keyframes Rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }

  .trans-rotate {
    animation: Rotate 1s;
  }
  
  .trans-from-left {
    animation: TransitionRight 1s;
  }
  
  .trans-from-right {
    animation: TransitionLeft 1s;
  }
  
  .trans-from-bottom {
    animation: TransitionFromButtom 1s;
  }
  
  .trans-from-top {
    animation: TransitionFromTop 1s;
  }