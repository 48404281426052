.hero-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bg-design {
    position: relative;
    /* margin-top: 1rem; */
}

.hero-contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: center;
    text-align: center;
    font-size: 1.1rem;
    line-height: 3rem;
    width: 40rem;
    /* background-color: green; */
    margin-top: -33rem;
}

.hero-contents h1 {
    font-weight: 300;
}

.bolder {
    font-weight: 500;
}

.green-b {
    color: #57cc99;
}

.red-b {
    color: #e34f4f;
}

.hero-contents img {
    margin-top: 10rem;
    margin-bottom: 11rem;
    width: 10rem;
}

.circular-2 {
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: animate-circle 5s linear forwards;
}

.scroll-down {
    cursor: pointer;
}


@keyframes animate-circle {

    0% 
    {
        stroke-dashoffset: 0;
    }

    /* 40% 
    {
        stroke-dashoffset: 2000;
    }

    80% 
    {
        stroke-dashoffset: 4000;
    } */

    100% 
    {
        stroke-dashoffset: 4000;
    }
}


@media only screen 
  and (max-device-width: 760px)  {
    /* Styles specific to iPhone XS Max/11 Pro Max */
    .hero-contents {
        width: 80%;
    }
    .hero-contents  h1{
        font-size: 2rem;
        line-height: 2rem;
    }
    .bg-design svg{
        opacity: 0;
        
    }

    .mockups {
        width: 20rem;
    }
}