.footer-container {
  background-color: rgb(25, 25, 25);
  padding: 2rem 4rem;
}

.footer-line {
  height: 2px;
  width: 100%;
  background-color: rgb(90, 89, 89);
  margin-bottom: 2rem;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.logo h3 {
  font-family: Poppins, sans-serif;
  font-weight: bold;
}

.end-contents {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.footer-sub-content1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-sub-content1 a {
  text-decoration: none;
  color: white;
}

.mail-logo {
  height: 2rem;
  padding: 0 1rem;
}

.connect {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-content: center; */
}

.privacy-pol {
  text-decoration: none;
  color: white;
}
.footer-links {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.end-contents {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .footer-container {
    padding: 2rem;
  }
  .end-contents {
    display: flex;
    align-items: flex-end;
  }

  .connect {
    display: flex;
    flex-direction: column;
  }
  .connect img {
    display: none;
  }
  .end-contents p {
    display: flex;
    flex-direction: column;
  }

  .footer-sub-content1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .footer-sub-content1 {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
  }
}
